import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi"
import ProfileIconHeader from "../../../components/ProfileIconHeader"
import { Link, useNavigate } from "react-router-dom"
import "./ReadingCatalogue.css"
import { Divider, Grid } from "@mantine/core"
import ReadingCatalogueCard from "./components/ReadingCatalogueCards/index"

let readingCatalogue = [
    {
        "categoryName": "R & W Fill in the Blanks",
        "categoryId": "0"
    },
    {
        "categoryName": "Multiple Choice",
        "categoryId": "1"
    },
    {
        "categoryName": "Re-order Paragraph",
        "categoryId": "2"
    },
    {
        "categoryName": "Fill In the Blanks",
        "categoryId": "3"
    },
    {
        "categoryName": "Multiple Choice, Single Answer",
        "categoryId": "4"
    }
]

const ReadingTestCatalogue = () => {
    const navigate = useNavigate()
    return (
        <div className="w-full lg:max-h-screen overflow-scroll pb-5  ">
            <ProfileIconHeader />
            {/* <div className="bg-white sticky  top-0 z-10">
                <div
                    onClick={() => navigate("/home")}
                    className="w-fit cursor-pointer flex gap-2 items-center p-3 lg:hidden"
                >
                    <button>
                        <PiCaretLeftBold />
                    </button>{" "}
                    Reading Question Types
                </div>
            </div> */}
            <main className="p-4 px-[51px] max-md:px-6">
                <div className="flex max-md:hidden items-center gap-2">
                    <Link to="/home">Home</Link> <PiCaretRightBold />{" "}
                    <p className="">Reading</p> <PiCaretRightBold />
                    <p className="text-primary-500">Tests</p>
                </div>
                <div className="pt-5 w-fit cursor-pointer flex gap-2 items-center" onClick={() => navigate("/home")}>
                    <button className="lg:hidden">
                        <PiCaretLeftBold />
                    </button>
                    <span className="heading">
                        Reading Question Types
                    </span>
                </div>
                <Divider my="xl" />

                <Grid>
                    {readingCatalogue?.map((catalogue) => (
                        <Grid.Col span={{ base: 12, sm: 12, lg: 12, xl: 6 }}>
                            <ReadingCatalogueCard catalogue={catalogue} />
                        </Grid.Col>
                    ))}
                </Grid>

            </main>
        </div>
    )
}

export default ReadingTestCatalogue